import React from 'react'
import content from "../../data/content.json"
import Feature from './Feature';
import FeatureCSS from "./Features.module.css"

const Features = () => {
  const features = content.features;

  return (
    <section className={FeatureCSS.containerSection} id="features">
      <h2 className={FeatureCSS.title}>Our <span className={FeatureCSS.titlePurple}>key features</span></h2>
      <div className={FeatureCSS.featuresContainer}>
        {Object.keys(features).map((key) => (
          <Feature 
            key={key}
            id={key}
            imageSrc={features[key].imageSrc}
            imageDescription={features[key].imageDescription}
            styledWordsText={features[key].styledWordsText}
            styledWordsHeading={features[key].styledWordsHeading}
            headingPartOne={features[key].headingPartOne}
            headingPartTwo={features[key].headingPartTwo}
            textPartOne={features[key].textPartOne}
            textPartTwo={features[key].textPartTwo}
          />
        ))}
      </div>
    </section>
  )
}

export default Features;