import React, {useEffect} from 'react'
import Navbar from '../components/header/Navbar'
import styles from '../styles/TermsAndConditions.module.css'
import Footer from '../components/footer/Footer'


const TermsAndConditions = () => {
   useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <>
        <Navbar />
        <div className={styles.container}>
            <h2 className={styles.title}>User Terms & Conditions</h2>
            <h3>1. General Provisions</h3>
            <p>Please carefully read this Terms of Use and Privacy Policy for Soundscaper Web Site.
               These Terms of Use (the “Terms”) of soundscaper.hr (the “Web Site”) govern the relationship between the User of the Web Site (the “User” or “You”) and the owner of the Web Site, company Soundscaper d.o.o. Split, hereinafter referred to as “Soundscaper” or “We”.
               Any use of the Web Site and all parts thereof is subject to the conditions set out below. If you do not agree to these Terms, you are obliged to immediately cease any use of this Web Site.
            </p>
            <h3>2. News</h3>
            <p>Soundscaper will use the information or e-mail addresses to contact You and to send You news and promote producers. You can change your mind and unsubscribe at any time by clicking the unsubscribe link at the bottom of received e-mails, or contact us at <a href='mailto:contact@soundscaper.hr'>contact@soundscaper.hr</a></p>
            <h3>3. Protected Content</h3>
            <p>Soundscaper is the holder of the rights on the Web Site and all of its contents, which includes the right to the hosted databases in accordance with the Law on Copyright and Related Rights. Users are authorized to use the Web Site, its contents and its databases only and solely in accordance with the authority acquired under the contract or in accordance with these Terms.
               Soundscaper is the holder of the rights regarding the design of the Web Site and its characteristic components. The aforementioned contents enjoy protection under the Copyright and Related Rights Act and other applicable regulations, and may not be copied or otherwise used without our written consent.
               The term Soundscaper is registered as a trademark in the Republic of Croatia and enjoy protection under the Trademark Act. Its use is only permitted with the written consent of Soundscaper.
            </p>
            <h3>4. Limitation of Liability</h3>
            <p>We collect and offer all information and other content available through the Web Site to our users in good faith, while making reasonable efforts with the attention of a good businessman to maximize their accuracy, completeness and reliability. However, Soundscaper does not guarantee, expressly or tacitly, the accuracy, reliability or completeness of any published data, materials or information, nor the promptness of uploading new content to the Web Site.
               Soundscaper makes every reasonable effort to ensure the proper functioning of the Web Site, but will not be responsible for the occasional and time-limited failure of the Web Site.
               Furthermore, certain content offered through the Web Site represents our subjective judgment, or opinion, which is generally generated automatically by processing information we collect from various sources. We give these estimates or opinions in good faith, but do not take responsibility for their correctness.
               The Web Site also contains information from third parties and links to other Web Sites – websites and/or content over which we have no editing ability. Whenever possible, such information shall be marked accordingly. In any case, we accept no responsibility for the accuracy, reliability or integrity of these third-party contents.
               Soundscaper, its owners, director, employees and associates do not accept and will not be liable for damage or injury that results from the use or inability to use any part of the Web Site.
            </p>
            <p className={styles.bold}>By continuing to use the Web Site you accept these Terms so You agree that Soundscaper will not be liable for any damages resulting from your use of the Web Site and the content offered through it.</p>
            <h3>5. Rights and Obligations of the User</h3>
            <p>The User acknowledges that he/she is aware of all the features and limitations of the Web Site, as well as the technical requirements related to the computer, computer settings and configuration required to use the Web Site.
               The User accepts that it is forbidden to use the Web Site, which is not solely for personal (own) purposes, retrieval of data for further public disclosure, commercial or non-commercial exploitation, as well as retrieving more data than usual and/or allowed.
            </p>
            <h3>6. Final Provisions</h3>
            <p>Soundscaper expressly reserves the right to modify or discontinue the content of the Web Site, partially or entirely modify the contents of the Web Site, the right to supplement or delete, as well as to suspend or permanently terminate the Web Site, in any way, at any time, for any reason, and shall not be liable for damages resulting from such changes.</p>
            <p>Soundscaper also reserves the right to change these Terms without previous announcements or special notice.</p>
            <p className={styles.bold}>These Terms of Use apply and are valid since October 17th, 2023.</p>
            <p>All comments according to the Article 11a. of the Consumer Protection Act can submitted in writing:
               to the registered office of the company at: Gajeva 17, Split
               or by email to: <a href='mailto:contact@soundscaper.hr'>contact@soundscaper.hr </a>
            <p>We are obliged to respond to the complaint within the time limit set by the Consumer Protection Act.</p>
            </p>
        </div>
        <Footer />
    </>
  )
}

export default TermsAndConditions;