import React from 'react'
import styles from "./Associates.module.css"

const Associate = ({imageDescription, imageSrc, link }) => {
  const openLinkInNewTab = () => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  return (
    <div className={styles.imageWrapper} onClick={openLinkInNewTab}>
      <img src={imageSrc} alt={imageDescription} className={styles.image}></img>
    </div>
  )
}

export default Associate