import React from 'react'
import FooterCSS from "./Footer.module.css"
import logotip from '../../Logotip.svg';
import { Link } from 'react-router-dom';

import emailjs from '@emailjs/browser';
import { useRef, useState } from 'react';

const Footer = () => {
    const form = useRef();
    const [userEmail, setUserEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
  

    const sendEmail = (e) => {
        e.preventDefault();

        if (isSubmitting) {
            return;
          }
        setIsSubmitting(true);

        emailjs.sendForm('service_v3oby1p', 'template_jxa67o5', form.current, 'oiP4LtcxcCGyUnERp')
            .then((result) => {
                console.log(result.text);
                setUserEmail('');
            }, (error) => {
                console.log(error.text);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

  return (
    <section className={FooterCSS.container}>
        <div className={FooterCSS.inputAndTextNewsletterContainer}>
            <p className={FooterCSS.pnews}>Get the latest information</p>
            <form ref={form} onSubmit={sendEmail} className={FooterCSS.subscribeContainer}>
                <input
                    placeholder={isSubmitting ? 'Submitting...' : 'Enter your email'}
                    type="email"
                    name="user_email"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    className={FooterCSS.input}
                    disabled={isSubmitting}
                />
                <button type="submit" className={FooterCSS.subscribeButton} disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Subscribe Now'}
                </button>
            </form>
        </div>

        <div className={FooterCSS.contentContainer}>
             <div className={FooterCSS.socialsAndInfoContainer}>
                
                    
                <div className={FooterCSS.logoAndSocialsContainer}>
                    <img src={logotip} alt="logo" className={FooterCSS.logo}></img>
                    <div className={FooterCSS.socialsContainer}>
                        <h4 className={FooterCSS.socialsContainerHeading}>Stay in touch</h4>
                        <Link to='https://www.linkedin.com/company/soundscaper'>
                            <img src="./linkedin.svg" alt="linkedin" className={FooterCSS.socialsContainerImg}></img>
                        </Link>
                        <Link to='https://www.instagram.com/soundscaper.hr/'>
                            <img src="./instagram.svg" alt="instagram" className={FooterCSS.socialsContainerImg}></img>
                        </Link>
                    </div>
                </div>

                <div className={FooterCSS.infoContainer}>
                    <div className={FooterCSS.infoContainerColumn}>
                        <h3 className={FooterCSS.infoContainerColumnHeading}>Company</h3>
                        <a href="/#home" className={FooterCSS.infoContainerColumnLinks}>Home</a>
                        <a href="/#features" className={FooterCSS.infoContainerColumnLinks}>Features</a>
                        <a href="/#aboutUs" className={FooterCSS.infoContainerColumnLinks}>About Us</a>
                        

                    </div>

                    <div className={FooterCSS.infoContainerColumn}>
                        <h3 className={FooterCSS.infoContainerColumnHeading}>Contact Us</h3>
                            <p className={FooterCSS.infoContainerColumnText}>+385 99 637 6214</p>
                            <a className={FooterCSS.infoContainerColumnText} href='mailto:contact@soundscaper.hr'>contact@soundscaper.hr</a>
                            <p className={FooterCSS.infoContainerColumnText}>Gajeva 17, 21000 Split</p>
                        
                    </div>
                </div>
            </div>
            <div className={FooterCSS.textBelowLine}>
                <p>Copyright</p>
                    <Link to='/PrivacyPolicy' className={FooterCSS.textBelowLineLink}><p>Privacy Policy</p></Link>
                    <Link to='/TermsAndConditions' className={FooterCSS.textBelowLineLink}><p>User Terms & Conditions</p></Link>
            </div>
        </div>
        {/* <hr className={FooterCSS.line}></hr> */}
        
    </section>
  )
}

export default Footer