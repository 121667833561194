import React from 'react'
import styles from "./Achievements.module.css"
import { useState, useEffect } from 'react';


const AchievementCard = ({id, title, description, imageSrc, imageDescription, link}) => {

    

    return (
        <div className={styles.card}>
            <div className={styles.innerCard}>
                <div className={styles.imageWrapper}>
                    <img src={imageSrc} alt={imageDescription} className={styles.image}></img>
                </div>
                <div className={styles.textContainer}>
                    <h3 className={styles.cardTitle}>{title}</h3>
                    <p className={styles.cardDescription}>{description}</p>
                    <a href={link} className={styles.link}>Read more</a>
                </div>
            </div>
            

        </div>



    )
}

export default AchievementCard