import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import styles from '../components/HomeBanner.module.css';

function PlayStore() {
  return (
    <>
      <Header />
      {/*We can change this and the home banner to be a component and pass in the text and source images */}
        <div style={{marginBottom: 200}} className={styles.container}>
          <div className={styles.contentWrapper}>
            <h1 className={styles.titleWrapper}>Oops...<span className={styles.titlePurple}>Under construction.</span></h1>
            <p className={styles.tagLine}>We are preparing something amazing for you!</p>
          </div>
            <img src="./construction.svg" alt="construction" id={styles.construction} className={styles.iphoneImage}></img>

        </div>
      <Footer />
      </>
  );
}

export default PlayStore;
