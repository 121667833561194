import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import PlayStore from './pages/PlayStore';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';

function BrowserSuggestionAlert() {
  const userAgent = navigator.userAgent;
  const isSamsungInternet = /SamsungBrowser/i.test(userAgent);

  useEffect(() => {
    if (isSamsungInternet) {
      window.alert(
        "For the best experience, we recommend using Google Chrome. Some features may not be fully supported on Samsung Internet."
      );
    }
  }, [isSamsungInternet]);

  return null; // Always return null for this component
}

function App() {
  return (
    <>
    <BrowserSuggestionAlert />
    <Router>
      <Routes>
      <Route path='/' exact element={<Home />} />
      <Route path="/playStore" element={<PlayStore />} />
      <Route path='/TermsAndConditions' element={<TermsAndConditions />} />
      <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
      <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
