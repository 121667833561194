import React, {useEffect} from 'react'
import Navbar from '../components/header/Navbar'
import styles from '../styles/TermsAndConditions.module.css'
import Footer from '../components/footer/Footer'

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
        <Navbar />
        <div className={styles.container}>
            <h2 className={styles.title}>Privacy Policy</h2>
            <h3>1. Privacy Protection</h3>
                <p><br/>Browsing the Web Site is anonymous and no information is collected to enable you to identify yourself.
                    <br/>Soundscaper automatically collects information about the device you access the website. Soundscaper uses this information for the purpose of offering its services, maintaining their quality and obtaining general statistics related to the use of the Web Site.
                    <br/>By entering your personal information in the fields provided for on the Web Site, you confirm that you have made your personal information available voluntarily and that you allow Soundscaper to use it for the purpose for which the information was provided, to which we will separately warn you. When you enter personal information in the fields provided on the Web Site, we will refer you to this Privacy Policy to become aware of your rights with respect to the protection of the personal data in question.
                    <br/>Accordingly, we hereby inform you that Soundscaper is the head of the processing of personal information you enter into the fields provided on the web site for this purpose, and the information in question is used solely for the purpose for which it was voluntarily provided.
                    <br/>For any questions and claims regarding the processing of your data, you can contact Soundscaper at <a href='mailto:contact@soundscaper.hr'>contact@soundscaper.hr </a>
                    <br/>The General Data Protection Regulation provides for the following rights: the right to be informed about the processing of your personal data, the right to correct inaccurate data, the right to delete and limit processing, the right to portability and the right to object.
                    <br/>You have the right to send a complaint regarding your personal information to <a href='mailto:contact@soundscaper.hr'>contact@soundscaper.hr </a> 
                    <br/>You can also file a complaint with the Data Protection Supervisor or the Agency for Personal Data Protection (AZOP).
                    <br/>Soundscaper may, if you have agreed to the use of cookies, use the information collected to provide you with more efficient customer service, facilitate the use of the Web Site by eliminating the need to enter the same information frequently, or to tailor the Web Site to your personal preferences and interests.
                    <br/>Soundscaper does not sell, rent or lend the personal information of the Web Site users to third parties and protect your personal information from unauthorized access. However, if you have agreed to the use of cookies, you may pass on your personal information to trusted business partners for specific purposes such as various statistical analyses, advertising, customer support or similar needs. In such cases, Soundscaper will prohibit your business partner from using your personal information for any purpose other than the agreed one, and will oblige the business partner to maintain the confidentiality of personal information.
                </p>
            <h3>2. Cookie Policy</h3>
                <p>Given the importance of personal information and our obligations of transparency, below we provide information about cookies, how we use them on Web Site, and what options you have if you would prefer to disable them despite the benefits they bring.</p>
                <h4>What are cookies?</h4>
                <p>Cookies are small text files that are usually downloaded to your computer, tablet or mobile device (hereinafter referred to as the “Device”) from the websites you visit. Cookies are easier to use as they save your web site settings (such as language selection) and reload them when you restart them. This way, the information contained on the website is tailored to your needs and common usage.</p>
                <h4>What types of cookies are there?</h4>
                <p>To get a better understanding of cookies and how to control them, you can find out below what types of cookies exist and which cookies are used on the website.
                    <li>First-party cookies: Cookies installed on your device by the organization whose website you visit are known as “first-party” cookies.</li>
                    <li>Third-party cookies: Cookies that are installed on your device by a website you visit by another organization are known as “third party” cookies. An example of a “third party” cookie is a cookie set by a website analytics firm (such as Google Analytics) that provides the website owner with information about the number of people who visit the site.</li>
                    <li>Persistent cookies: Permanent cookies remain stored on your device after closing your web browser. With the help of these cookies, websites store information to make it easier for you to use them. For example, websites that require a username and password will remember your entry that will appear each time you visit.</li>
                    <li>Session cookies: Temporary cookies are removed from your device when you close the web browser in which you were browsing the website. With these cookies, the pages store temporary information that is used for its proper functioning.</li>
                </p>
            <h3>3. What cookies does Soundscaper use?</h3>
                <p>To improve the user experience, the Web Site uses all of the cookies listed (first- and third-party cookies, permanent and temporary cookies).</p>
                <h4>Functionality of the website</h4>
                <p>The Website uses cookies (first-party and permanent) that are necessary for the proper and technical functionality or that provide the service or option you have requested (for example, a cookie that “remembers” your choice to accept or decline third-party cookies).</p>
                <h4>Website traffic statistics and analytics</h4>
                <p>The Web Site uses tracking cookies (third parties) to help us improve by providing us with aggregate statistics and analytics on user traffic, which parts of the page are most visited, the number of visits from various sources, or through organic search (for example, Google Search). Third-party cookies used by the Website do not personally identify you and are completely anonymous. Advanced statistics and analytics tracking settings (including demographic data collection and customer interest) help us improve our services and provide you with information about new products or services that you can use.</p>
                <h4>Ads targeted based on the interest of the Web Site visitors.</h4>
                <p>In order to provide you with the most relevant information about our products and services, we use third-party cookies for remarketing, interest-targeted advertising to make you aware of the content you have viewed on the Website. We may use this information for the purpose of our advertising campaigns on third-party websites. In that case, we may get feedback on the websites of our marketing partners where you have seen our ads. Similarly, third parties may become aware that you have visited our website.</p>
                <h4>Tracking cookies that are not set.</h4>
                <p>To disable tracking cookies, select the option to remove cookies. If you want to set tracking cookies in the future to improve the user experience and help us create targeted content, select the cookie setting option.</p>
                <h4>How to control cookies?</h4>
                <p>As explained previously, cookies help to create the best user experience on a website. If you choose to disable cookies, you may find that some aspects of the website do not work as expected. In addition to the specific disabling of cookies we have described, you can set your web browser to disable cookies if you do not wish to receive them. Keep in mind that most browsers offer different levels of privacy (such as allowing first-party cookies but blocking third-party cookies) or notifying you every time a website wants to set a cookie. Keep in mind that disabling cookies prevents you from setting new cookies, but it will not remove the cookies you have previously set unless you delete all cookies in your browser settings.</p>
        </div>
        <Footer />
    </>
  )
}

export default PrivacyPolicy;