import React from 'react';
import Navbar from './Navbar';

const Header = () => {
  return (
    <header id='home'>
      <Navbar />
    </header>
  );
}

export default Header;
