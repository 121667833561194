import '../App.css';
import Header from '../components/header/Header';
import HomeBanner from '../components/HomeBanner';
import Features from '../components/features/Features';
import Associates from '../components/associates/Associates';
import AboutUs from '../components/about/AboutUs';
import Footer from '../components/footer/Footer';
import Achievements from '../components/achievements/Achievements';

function Home() {
  return (
    <div className="Home">
      <Header />
      <HomeBanner />
      <Associates />
      <Features /> 
      <Achievements/>
      <AboutUs /> 
      <Footer />
    </div>
  );
}

export default Home;
