import React from 'react'
import content from "../../data/content.json"
import styles from "./Achievements.module.css"
import { useState, useEffect } from 'react';
import AchievementCard from './AchievementCard';


const Achievements = () => {


    const achievements = content.achievements;
    const [activeIndex, setActiveIndex] = useState(0);

  
    const [touchStartPosition, setTouchStartPosition] = useState(0);
    const [touchEndPosition, setTouchEndPosition] = useState(0);
    const [touched, setTouched] = useState(false);
    const[swiped, setSwiped] = useState(false);

    const updateIndex = (newIndex) => {
      if(newIndex < 0){
        newIndex = 0;
      }else if (newIndex >= achievements.length){
        newIndex = achievements.length - 1;
      }
      translateFullSlides(newIndex);
      setActiveIndex(newIndex);
    }

    
    /*Used when arrow buttons or navigation buttons are pressed */
    const translateFullSlides = (newIndex) => {
      let toTranslate = -100*newIndex;
      let displayFrame = document.getElementById("Display");
      displayFrame.style.transform = `translateX(${toTranslate}%)`;

    }

    /*Used when swiping on phone*/
    const translatePartialSlides = (distanceToTranslate) => {
      let currentTranslation = -100*activeIndex;
      let totalTranslation = currentTranslation + distanceToTranslate;
      let displayFrame = document.getElementById("Display");
      displayFrame.style.transform = `translateX(${totalTranslation}%)`;

    }

    /* Used for snappier animation when swiping on phone(more responsive to the touch) */
    const speedUpAnimation = () => {
        let displayFrame = document.getElementById("Display");
        displayFrame.classList.add(styles.fastAnimation);
    }
    const slowDownAnimation = () => {
      let displayFrame = document.getElementById("Display");
      displayFrame.classList.remove(styles.fastAnimation);
    }

    /*Handlers for touch swiping */
    const touchStartHandler = (e) =>{
      speedUpAnimation();
      setTouchStartPosition(e.targetTouches[0].clientX);
      setTouchEndPosition(e.targetTouches[0].clientX);
      setTouched(true);
    }

    const touchMoveHandler = (e) =>{
      setTouchEndPosition(e.targetTouches[0].clientX);

      let displayFrameWidth = document.getElementById("Display").offsetWidth;
      let distanceToTranslate = (touchEndPosition - touchStartPosition) / displayFrameWidth * 100;

      translatePartialSlides(distanceToTranslate);

      if(touched === true)
        setSwiped(true);
    }

    const touchEndHandler = (e) =>{
      if(swiped === true){
        slowDownAnimation();
        if(touchStartPosition - touchEndPosition > 75){
          updateIndex(activeIndex + 1);
        }else if(touchStartPosition - touchEndPosition < -75){
          updateIndex(activeIndex - 1);
        }else{
          translateFullSlides(activeIndex);

        }
      }
      setTouched(false);
      setSwiped(false);
    }
    
   
  return (
    
    <section id='aboutUs' className={styles.container}>
      <h2 className={styles.title}>Our <span className={styles.titlePurple}>achievements</span></h2>
      {/* <div className={styles.background}></div> */}

      
      <div className={styles.achievementsContainer}>
        <div className={styles.displayFrame} 
              id="Display"
              onTouchStart={(e)=>touchStartHandler(e)}
              onTouchMove={(e)=> touchMoveHandler(e)}
              onTouchEnd ={(e) => touchEndHandler(e)}
        >
          {achievements.map((achievement) => {
            return <AchievementCard
              key={achievement.id}
              id={achievement.id}
              title={achievement.title}
              description={achievement.text}
              imageSrc={achievement.imageSrc}
              imageDescription={achievement.imageDescription}
              link={achievement.link}
            />
          })}
        </div>
      </div>

      <div className={styles.navigation}>
        
        <button className={styles.leftArrow}
          onClick={() => {
            updateIndex(activeIndex - 1);
          }}
        >
          <img src="/arrow.svg" alt="arrow" className={activeIndex === 0 ? styles.arrowDisable :styles.arrowImage}></img>
        </button>

        <div className={styles.indicators}>
          {achievements.map((achievement, index) => {
            return (
              <button key={achievement.id} className={index === activeIndex ? styles.indicatorButtonsSelected : styles.indicatorButtons}
                onClick={() => {
                  setActiveIndex(index);
                  translateFullSlides(index);
                }}
              >


              </button>
            );
          })}

        </div>


        <button className={styles.rightArrow}
            onClick={() => {
              updateIndex(activeIndex + 1);
            }}
        >
          <img src="/arrow.svg" alt="arrow" className={activeIndex === achievements.length - 1 ? styles.arrowDisable :styles.arrowImage}></img>
        </button>

      </div>


      
    </section>
  )
}

export default Achievements