import React from 'react'
import styles from "./AboutUs.module.css"

const TeamMember = ({ name, jobs, imageSrc, imageDescription}) => {
  return (
    <div className={styles.teamMemberWrapper}>
      <img src={imageSrc} alt={imageDescription} className={styles.image}></img>
      <div className={styles.nameJobWrapper}>
        <h3 className={styles.name}>{name}</h3>
        {jobs.map((job, index) => {
          return <p key={index} className={styles.job}>{job}</p>
        })}
      </div>
    </div>
  )
}

export default TeamMember