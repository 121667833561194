import React from 'react'
import FeaturesCSS from "./Features.module.css"
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const Feature = ({id, imageSrc, imageDescription , styledWordsText, styledWordsHeading, headingPartOne, headingPartTwo, textPartOne, textPartTwo}) => {
  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, []);
  
  return (
    <div className={FeaturesCSS.featureContainer} data-aos="fade-up" data-aos-offset="200">
      <div className ={FeaturesCSS.featureText}>
        <h3 className={FeaturesCSS.featureHeading}>{headingPartOne} <span className={FeaturesCSS.featureHeadingPurple}>{styledWordsHeading}</span>{headingPartTwo}</h3>
        <p className={FeaturesCSS.featureDesc}>{textPartOne} <span className={FeaturesCSS.featureDescOrange}>{styledWordsText}</span> {textPartTwo}</p>

      </div>
      <div className={FeaturesCSS.line}><div className={FeaturesCSS.temp}>a</div></div>
   
      <img className={FeaturesCSS.featurePic} src={imageSrc} alt={imageDescription}></img>
    
    </div>
  )
}

export default Feature