import React from 'react'
import content from "../../data/content.json"
import Associate from './Associate'
import styles from "./Associates.module.css"
import 'aos/dist/aos.css';

const Associates = () => {
  const associates = content.associates;

  return (
    <section id='associates' className={styles.container}>
      <h2 className={styles.title}>Associated with</h2>
      <div className={styles.associatesWrapper} data-aos="fade-up" data-aos-offset="100">
        {Object.keys(associates).map((key) => (
            <Associate 
            key={key}
            id={key}
            imageDescription={associates[key].imageDescription}
            imageSrc={associates[key].imageSrc}
            link={associates[key].link}
          />
        ))}
      </div>
    </section>
  )
}

export default Associates