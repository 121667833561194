import React from 'react'
import TeamMember from './TeamMember'
import content from "../../data/content.json"
import styles from "./AboutUs.module.css"
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const AboutUs = () => {
    const teamMembers = content.team;

    useEffect(() => {
      Aos.init({ duration: 1500, once: true });
    }, []);

  return (
    
    <section  className={styles.container}>
      <h2 className={styles.title}>Meet the <span className={styles.titlePurple}>team</span></h2>
      <div className={styles.teamWrapper} data-aos="fade-up" data-aos-offset="500">
        {Object.keys(teamMembers).map((key) => (
            <TeamMember 
            key={key}
            id={key}
            name={teamMembers[key].name}
            jobs={teamMembers[key].jobs}
            imageSrc={teamMembers[key].imageSrc}
            imageDescription={teamMembers[key].imageDescription}
          />
        ))}
      </div>
    </section>
  )
}

export default AboutUs