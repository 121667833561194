import styles from './HomeBanner.module.css'

const HomeBanner = () => {

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <h1 className={styles.titleWrapper}>Your Ultimate <span className={styles.titlePurple}>Audio Guide</span></h1>
        <p className={styles.tagLine}>Discover the undiscovered and experience ancient history</p>
        <p className={styles.description}>Time to let your curiosity lead the way as you discover untold stories, 
           immerse yourself in captivating audio, and explore every corner of your destination.
           <br></br>Get ready to elevate your adventures with the power of sound.</p>
      </div>
        <img src="./iphone15-mockup.png" alt="iphone15-pro" className={styles.iphoneImage}></img>
        <img src="./next-section-arrow.svg" alt='next-section-arrow' className={styles.arrow}></img>
    </div>
  )
}

export default HomeBanner