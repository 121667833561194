import React, { useState, useEffect } from 'react';
import logo from '../../Logotip.svg';
import styles from './Navbar.module.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [activeLink, setActiveLink] = useState('#home');

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
      const anchors = document.querySelectorAll('a[href^="#"]');
      const offsets = {};
      anchors.forEach((anchor) => {
        const target = document.querySelector(anchor.getAttribute('href'));
        if (target) {
          offsets[anchor.getAttribute('href')] = target.getBoundingClientRect().top + window.scrollY;
        }
      });
  
      let closestSection = null;
      let minDistance = Number.MAX_VALUE;
      Object.keys(offsets).forEach((key) => {
        const distance = Math.abs(offsets[key] - scrollPosition);
        if (distance < minDistance) {
          minDistance = distance;
          closestSection = key;
        }
      });
      setActiveLink(closestSection);
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    setClick(!click);
  };

  const closeMobileMenu = () => {
    setClick(false);
  };

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  return (
    <>
      <nav className={styles.nav}>
        <a href="/#home" className={styles.logoMobile} onClick={() => { closeMobileMenu(); scrollToTop(); }}>
          <img src={logo} className={styles.appLogo} alt="logo" />
        </a>
        <div className={styles.menuIcon} onClick={handleClick}>
          <i className={click ? 'fa fa-times' : 'fa fa-bars'} />
        </div>
        <div className={`${styles['navMenu']} ${click ? styles['navMenuActive'] : ''}`}>
          <a href="/#home" className={`${styles['navLinks']} ${activeLink === '#home' ? styles['navLinksActive'] : ''}`} onClick={() => { closeMobileMenu(); scrollToTop(); }} >
            Home
          </a>
          <a href="/#features" className={`${styles['navLinks']} ${activeLink === '#features' ? styles['navLinksActive'] : ''}`} onClick={() => { closeMobileMenu(); }} >
            Features
          </a>
          <a href="/#aboutUs" className={`${styles['navLinks']} ${activeLink === '#aboutUs' ? styles['navLinksActive'] : ''}`} onClick={() => { closeMobileMenu(); }} >
            About us
          </a>
          <Link to='/playStore' className={styles.navButton} onClick={() => { closeMobileMenu(); scrollToTop(); }}>
          Get the App
          </Link>
        </div>
      </nav>
    </>
  );
};

export default Navbar;