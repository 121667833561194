import React from 'react'
import styles from '../styles/NotFound.module.css';

const NotFound = () => {
  return (
    <section className={styles.container}>
      <h1 className={styles.oops}>Oops!</h1>
      <p className={styles.lostText}>You are lost.</p>
      <img src="./not-found.svg" alt="404-not-found" className={styles.notFoundImage}></img>
      <a href='/#home' className={styles.goBack}>
        <img src="./back-arrow.svg" alt="back-arrow" className={styles.backArrow}></img>
        <p className={styles.goHomeText}>Go home</p>
      </a>
    </section>
  )
}

export default NotFound